import Link from "next/link";
import styled from "styled-components";

const StyledWrapper = styled.div`
  border-top: 1px solid var(--raised-background-color);
  display: flex;
  justify-content: center;
  height: 140px;
  overflow: hidden;
  @media screen and (max-width: 992px) {
    height: auto;
    padding: 2rem 0;
    font-size: 12px;
  }
  font-family: "Open Sans", sans-serif;
`;
const StyledContainer = styled.div`
  width: calc(100% - 6rem);
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const StyledSocialMediaWrapper = styled.div`
  display: flex;
  a {
    margin-left: 2rem;
  }
  g,
  path {
    fill: var(--text-color);
  }
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    a {
      margin-left: 0;
      margin-right: 2rem;
    }
    flex-direction: column;
  }
`;
const StyledLeftWrapper = styled.div`
  > div:first-child {
    margin-bottom: 1rem;
  }
`;
const StyledLeftLinks = styled.div`
  display: flex;
  font-size: 1rem;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    flex-direction: column;
  }
  a {
    margin-right: 1rem;
    color: var(--text-color);
    text-decoration: underline;
  }
`;

const Footer = () => {
  const copyrightString = `© ${new Date().getFullYear()} Redact - All rights reserved`;
  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledLeftWrapper>
          <StyledLeftLinks>
            <Link href="/affiliates">Affiliates</Link>
            <Link href="/press-kit">Press Kit</Link>
            <Link
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/blog";
              }}
              href="/blog"
            >
              Blog
            </Link>
            <Link href="/privacy">Privacy Policy</Link>
            <Link href="/terms">Terms & Conditions</Link>
            <Link href="/contact">Contact</Link>
          </StyledLeftLinks>
        </StyledLeftWrapper>
        <StyledSocialMediaWrapper>
          <div>{copyrightString}</div>
        </StyledSocialMediaWrapper>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Footer;
